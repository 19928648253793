import Footer from "./Footer";
import Header from "./Header";
import Newsletter from "./Newsletter";
import Head from 'next/head';

const Layout = ({
    children, 
    currentRoute,
    title,
    description,
    image,
    canonical,
    type,
    audio,
    video,
    schema
}) => {
    let canonicalPath = canonical?.cachedUrl

    if (canonicalPath == "home") {
        canonicalPath = "";
    }

    return (
        <>
            <Head>
                <link key="storyblok-prefetch" rel="dns-prefetch" href="https://app.storyblok.com" />
                <link key="googletagmanager-prefetch" rel="dns-prefetch" href="https://www.googletagmanager.com" />
                <meta key="viewport" name="viewport" content="width=device-width, initial-scale=1" />
                {title && <title key="title">{title}</title>}
                {description && <meta key="description" name="description" content={description} />}
                {title && <meta key="ogtitle" property="og:title" content={title} />}
                {description && (<meta
                key="ogdescription"
                property="og:description"
                content={description}
                />)}
                {image?.filename && <meta
                key="ogimage"
                property="og:image"
                content={image?.filename + "/m/"}
                />} 
                {canonical?.cachedUrl && <link key="canonical" rel="canonical" href={"https://www.richardbilton.com/" + canonical?.cachedUrl} />}
                <script
                        type="application/ld+json"
                        dangerouslySetInnerHTML={schema}
                        key="article-jsonld"
                    />
            </Head>
            <Header currentRoute={currentRoute} />
            {children}
            <Newsletter />
            <Footer />
        </>
    )
}
export default Layout;