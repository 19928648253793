import { useState } from "react"

export default function Newsletter() {
    const [status, setStatus] = useState({
        submitted: false,
        submitting: false,
        info: { error: false, msg: null }
    });
    
    const [inputs, setInputs] = useState({
        subscribe__email: ""
    });
    
    const handleResponse = (status, msg) => {
        if (status === 201 || status === 200) {
          setStatus({
            submitted: true,
            submitting: false,
            info: { error: false, msg: msg },
          });
          setInputs({
            subscribe__email: ""
          });
        } else {
          setStatus({
            info: { error: true, msg: msg },
          });
        }
    };

    const handleOnChange = (e) => {
        e.persist();
        setInputs((prev) => ({
            ...prev,
            [e.target.id]: e.target.value,
        }));
        setStatus({
            submitted: false,
            submitting: false,
            info: { error: false, msg: null },
        });
    };
    
    const handleOnSubmit = async (e) => {
        e.preventDefault();
        setStatus((prevStatus) => ({ ...prevStatus, submitting: true }));
    
        let $email = inputs.subscribe__email;


    
        if (!$email.match(/\S+@\S+/)) {
          handleResponse(400, `There's an issue with your provided email address. Please try again.`);
          return;
        }


    
        const res = await fetch('/api/subscribe', {
          body: JSON.stringify({
            email: $email
          }),
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
          },
          method: 'POST'
        });
    
        try {
          await res.json();
          handleResponse(200, 'Thank you for signing up!');
        } catch (e) {
          handleResponse(400, `There's an issue signing up for the newsletter. Please try again.`)
        }
    };
    

    return (
      <div id="newsletter" className="py-16 sm:py-24 lg:py-32 bg-light-1">
        <div className="mx-auto grid max-w-7xl grid-cols-1 gap-10 px-6 lg:grid-cols-12 lg:gap-8 lg:px-8">
          <div className="max-w-xl text-3xl tracking-tight leading-snug text-base sm:text-4xl lg:col-span-7">
            <h2 className="inline sm:block lg:inline xl:block">Want article news and updates?</h2>{' '}
            <p className="inline sm:block lg:inline xl:block">Sign up for my newsletter.</p>
          </div>
          <form onSubmit={handleOnSubmit} className="w-full max-w-md lg:col-span-5 lg:pt-2">
            <div className="flex gap-x-4">
              <label htmlFor="subscribe__email" className="sr-only">
                Email address
              </label>
              <input
                id="subscribe__email"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="min-w-0 flex-auto rounded-md border border-gray-900/10 px-[calc(theme(spacing.4)-1px)] py-[calc(theme(spacing[1.5])-1px)] text-base leading-7 text-gray-900 placeholder-gray-500 shadow-sm focus:border-indigo-600 focus:ring-indigo-600"
                placeholder="Enter your email"
                onChange={handleOnChange}
                value={inputs.subscribe__email}
              />
              <button
                type="submit"
                className="flex-none rounded-md bg-gray-900 py-1.5 px-3.5 text-base font-semibold leading-7 text-white shadow-sm hover:bg-gray-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {!status.submitting
                ? !status.submitted
                    ? "Join"
                    : "Subscribed"
                : "Please wait"}
              </button>
            </div>
            <p className="mt-4 text-sm leading-6 text-base">
              Read the{' '}
              <a href="/privacy" className="font-semibold text-base hover:text-primary-1">
                privacy&nbsp;policy
              </a>
              . {status.info.error && status.info.msg }{!status.info.error && status.info.msg && status.info.msg}
            </p>
            
          </form>
        </div>
      </div>
    )
  }