import Container from "./Container";
import Social from "./Social";

export default function Footer({}) {
    return (
        <footer id="footer" className="bg-white">
            <Container className="mx-auto max-w-7xl py-12 px-6 md:flex md:items-center md:justify-between lg:px-8">
                <div className="flex justify-center space-x-6 md:order-2">
                    <Social />
                </div>
                <div className="mt-8 md:order-1 md:mt-0">
                    <p className="text-center text-xs leading-5 text-gray-500">
                        &copy; {new Date().getFullYear()} Richard Bilton, Inc. All rights reserved.
                    </p>
                </div>
            </Container>
        </footer>
    )
}