import Container from "./Container";
import Link from 'next/link';

export default function Header({currentRoute}) {
    
    const activeClass = "font-bold font-sans text-sm uppercase";
    const notActiveClass = "font-normal font-sans text-sm uppercase";

    return (
        <div id="header" className="border-b">
            <Container className="flex flex-row justify-center items-center h-12">
                <nav>
                    <ul className="flex">
                        <li className="pr-4">
                            <Link href="/" className={currentRoute === '/' ? activeClass : notActiveClass } prefetch={false}>
                                Home
                            </Link>
                        </li>
                        <li className="px-4">
                            <Link href="/about" className={currentRoute === '/about' ? activeClass : notActiveClass } prefetch={false}>
                                About
                            </Link>
                        </li>
                        <li className="px-4">
                            <Link href="/blog" className={currentRoute === '/blog' ? activeClass : notActiveClass } prefetch={false}>
                                Blog
                            </Link>
                        </li>
                        <li className="pl-4">
                            <Link href="/contact" className={currentRoute === '/contact' ? activeClass : notActiveClass } prefetch={false}>
                                Contact
                            </Link>
                        </li>
                    </ul>
                </nav>
            </Container>
        </div>
    )
}